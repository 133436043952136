.foot {
    height: 70px;
    width: 100%;
    padding: 0.5rem 0.5rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    bottom: 0;
    left: 0;
    background-color: rgb(19, 19, 19);
    margin-top: auto;
}

.containerF {
    justify-content: space-between;
    gap: 5px;
    width: 100%;
    height: 100%;
    display: flex;
}

.colS {
    vertical-align: middle;
    flex: 40%;
    margin: auto;
}

.colC {
    flex: 20%;
    margin: auto;
    vertical-align: middle;
}

.copyR {
    font-size: max(1.2vw, 10px);
    float: left;
}

.credits {
    font-size: max(1.2vw, 10px);
    float: right;
}

.linkF {
    color: rgb(56, 231, 255);
    font-size: max(1.2vw, 10px);
    cursor: pointer;
    text-align: center;
    text-decoration: underline;
}

.linkF:hover {
    color: rgb(56, 156, 172);
}

.linkF:active {
    color: rgb(255, 184, 0)
}

.name {
    color: rgb(56, 231, 255);
}

@media screen and (min-width:1200px) {
    .copyR {
        font-size: 0.8rem;
    }

    .credits {
        font-size: 0.8rem;
    }

    .linkF {
        font-size: 0.8rem;
    }

    .foot {
        height: 60px;
        padding: 0.25rem 1rem;
    }
}