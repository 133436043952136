.divAreas{
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 8vw;
}

.divCarousel{
    justify-content: center;
    align-items: center;
    display: flex;
    padding-top: 20vw;
    width: 90%;
    max-width: 1200px;
    margin: auto;
    padding-bottom: 25vw;
    z-index: 1;
}

.cardContainer{
    align-items: center;
    justify-content: center;
}

.cardImg{
    width: 30vw;
    max-width: 20rem;
    height: auto;
    border-radius: 1rem;
    cursor: pointer;
    border: 3px solid rgb(56, 231, 255);;
}

@media screen and (min-width: 1200px){
    .divAreas{
        padding-top: 90px;
    }

    .divCarousel{
        padding-top: 200px;
        padding-bottom: 250px;
    }

    .card{
        font-size: 2rem;
    }
}   



