nav {
    height: 15vw;
    width: 100%;
    margin: 0 0;
    padding: 0rem 3vw;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
    max-height: 5rem;
    background-color: rgb(19, 19, 19);
    align-items: center;
}

.navIcon {
    height: 3vw;
    max-height: 3rem;
    width: auto;
    margin-top: 1vw;
    position: relative;
}

.logo {
    object-fit: cover;
    height: 10vw;
    max-height: 4rem;
    cursor: pointer;
    border: none;
}

ul {
    display: inline-flex;
    list-style: none;
    gap: 20px;
    align-items: center;
}

.topMenuListItem {
    cursor: pointer;
    font-size: 2vw;
    position: relative;
    font-family: "Ovo", serif;
    letter-spacing: 0.5vw;
}

.topMenuListItem::after {
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    bottom: 0;
    width: 0%;
    background: rgb(255, 184, 0);
    transition: width 0.5s;
}

.topMenuListItem:hover:after {
    width: 100%;
}

.submenu {
    list-style: none;
    background-color: rgb(148, 148, 148);
    color: white;
    position: absolute;
    border-radius: 5px;
    padding: 0.25rem;
    display: flex;
    flex-direction: column;
    gap: 1vw;
    align-items: flex-start;
}

.submenuItem {
    cursor: pointer;
    font-size: 1.5vw;
    position: relative;
    font-family: "Questrial", sans-serif;
    letter-spacing: 0.5vw;
}

.submenuItem::after {
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    bottom: 0;
    width: 0%;
    background: rgb(255, 184, 0);
    transition: width 0.5s;
}

.submenuItem:hover::after {
    width: 100%;
}

@media screen and (min-width: 1200px) {
    .topMenuListItem {
        font-size: 1.5rem;
        margin-left: 1rem;
        letter-spacing: 0.5rem;
    }

    .navIcon {
        margin-top: 0rem;
    }

    .submenu {
        padding: 1rem;
        gap: 1rem;
    }

    .submenuItem {
        font-size: 1.2rem;
        letter-spacing: 0.5rem;
    }
}