.introduction {
    overflow: auto;
    display: flex;
}

.divText {
    float: left;
    padding-top: 3vw;
    margin-left: 10vw;
    position: relative;
    left: 0;
}

.hello {
    font-size: 3vw;
    font-family: "Questrial", sans-serif;
    letter-spacing: 0.5vw;
}

.introName {
    color: rgb(56, 231, 255);
    font-size: 5vw;
    font-family: "Ovo", serif;
    letter-spacing: 1vw;
}

.textmech {
    font-size: 3vw;
    text-indent: 24vw;
    font-family: "Questrial", sans-serif;
    letter-spacing: 0.5vw;
}

.introText {
    width: 85vw;
}

.divImg {
    position: absolute;
    right: 0;
    float: right;
    padding-top: 2vw;
}

.introImg {
    height: 65vw;
    max-height: 50rem;
    width: auto;
    z-index: -1;
}

@media screen and (min-width: 1200px) {
    .divText {
        padding-top: 30px;
        margin-left: 7rem;
    }

    .divImg{
        padding-top: 20px;
    }

    .hello {
        font-size: 2.5rem;
        letter-spacing: 0.5rem;
    }

    .introName {
        font-size: 3.5rem;
        letter-spacing: 1rem;
    }

    .textmech {
        font-size: 2.5rem;
        text-indent: 16rem;
        letter-spacing: 0.5rem;
    }

    .introText {
        width: 65rem;
    }

    .divIntro {
        margin-left: 115px;
    }
}